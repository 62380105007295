import { MidiJSON } from '@tonejs/midi'
import { Express } from 'express'
import { NextServer } from 'next/dist/server/next'

import { PianoRollNote } from '../components/ai-playground/InteractivePianoRoll/types'
import { defaultInstrument, defaultLayer } from './instrumentsUtils'

export type Chord = {
  midi: Array<number>
  midiTimings: Array<Array<{ midiTiming: number; duration: number; velocity: number }>>
  id: number
  name: string
  degree: string
  duration: number | undefined
  octave: number | undefined
  settings: {
    velocity: number
  }
  voicings: Array<{ midi: number[]; name: string }>
  prediction?: number
  locked?: boolean
  draft?: boolean
}

export type GuitarStringValue = number | 'X' | 'FREE'

export type GuitarChord = {
  notes: string[]
  strings: GuitarStringValue[]
}

export const defaultView: ViewConfig = {
  pianoOpen: false,
  guitarOpen: false,
  notationOpen: false,
  drumsOpen: false,
  generatorOpen: false,
  editorOpen: false,
  layersOpen: null,
  lyricsOpen: false,
  drumsEditorOpen: false,
  mobileTab: 'chords',
  chordEditMode: 'ai-suggestions',
  onboardingOpen: false,
  melodyEditorOpen: false,
}
export const defaultLyrics = {
  title: '',
  lines: [],
}
export const defaultProg: Prog = {
  name: 'New Draft',
  key: 'C',
  scale: 'major',
  bpm: 120,
  volume: 100,
  shareEnabled: false,
  isMetronomeEnabled: false,
  parts: [
    {
      id: 1,
      name: 'Verse',

      chords: [],
      chordLayers: [],
      chordsVolume: 0,
      chordsMuted: false,

      melodyShown: false,
      melody: null,
      melodyLayers: [],
      melodyVolume: 0,
      melodyMuted: false,

      drumsShown: false,
      drums: null,
      drumLayers: [],
      drumsVolume: 0,
      drumsMuted: false,

      generatorSettings: {
        chordGenreKey: 'pop',
        drumGenreKey: 'trap-scorpion',
      },
      loops: 1,
      draft: false,
    },
  ],
}
export const defaultPart: ProgPart = {
  id: 0,
  name: '',

  chords: [],
  chordLayers: [],
  chordsVolume: 100,
  chordsMuted: false,

  melodyShown: false,
  melody: null,
  melodyLayers: [],
  melodyVolume: 100,
  melodyMuted: false,

  drumsShown: false,
  drums: null,
  drumLayers: [],
  drumsVolume: 100,
  drumsMuted: false,

  generatorSettings: {
    chordGenreKey: 'pop',
    drumGenreKey: 'trap-scorpion',
    emptyGenre: false,
  },
  loops: 1,
  draft: false,
}
export const defaultChord: Chord = {
  midi: [],
  midiTimings: [],
  id: 0,
  name: '',
  degree: '',
  duration: 1,
  octave: 0,
  settings: {
    velocity: 100,
  },
  voicings: [],
}

export const getDefaultPart = (fromEmpty?: boolean, withMelody = false, withDrums = false): ProgPart => {
  return {
    ...defaultPart,
    melodyShown: withMelody,
    drumsShown: withDrums,
    generatorSettings: {
      ...defaultPart.generatorSettings,
      emptyGenre: fromEmpty,
    },
  }
}

export const getDefaultProgWithChords = (chords: Chord[], insturmentKeys?: string[]): Prog => {
  const chordLayers = (insturmentKeys || []).map((key) => ({
    ...defaultLayer,
    instrument: { ...defaultInstrument, key },
  }))
  return {
    ...defaultProg,
    componentKey: Math.random(),
    parts: [{ ...getDefaultPart(), chords, chordLayers }],
  }
}

export type TMobileTab = 'chords' | 'lyrics' | 'settings'

export type ViewConfig = {
  pianoOpen: boolean
  guitarOpen: boolean
  notationOpen: boolean
  melodyEditorOpen: boolean
  drumsOpen: boolean
  generatorOpen: boolean
  editorOpen: boolean
  layersOpen: 'CHORDS' | 'MELODY' | 'DRUMS' | null
  lyricsOpen: boolean
  drumsEditorOpen: boolean
  tempFavInstrument?: string
  mobileTab: TMobileTab
  chordEditMode?: ChordEditMode
  onboardingOpen: boolean
}

export type ChordEditMode = 'voicings' | 'ai-suggestions' | 'custom' | 'diatonic' | 'secondary'

export const LyricsMoodItems = {
  inLove: 'In Love',
  fun: 'Fun',
  anger: 'Anger',
  happy: 'Happy',
  sad: 'Sad',
  heartbroken: 'Heartbroken',
}

export type LyricsMood = typeof LyricsMoodItems[keyof typeof LyricsMoodItems]

export type LyricsCreativity = 'low' | 'medium' | 'high'

export type Lyrics = {
  title: string
  lines: string[]
}

export type ProgCommon = {
  name: string
  //
  key: string
  scale: string
  //
  bpm: number
  volume: number
  //
  shareEnabled: boolean
  isMetronomeEnabled: boolean
  //
  componentKey?: number
}

export type Prog = {
  parts: Array<ProgPart>
} & ProgCommon

export type ProgDb = {
  parts: Array<ProgPartDb>
} & ProgCommon

export type MelodyPattern = {
  notes: PianoRollNote[]
  tempo: number
  length?: number | null
} | null

export type ProgPartPattern = {
  tempo: number
  length?: number | null
  groups: {
    percTypes: { type: string; active: boolean }[]
    pattern: { key: string; buffer: string }
  }[]
} | null

export type ProgPartCommon = {
  id: number
  name: string

  chords: Array<Chord>
  chordsVolume: number
  chordsMuted: boolean

  melodyShown: boolean
  melody: MelodyPattern
  melodyVolume: number
  melodyMuted: boolean

  drumsShown: boolean
  drums: ProgPartPattern
  drumsVolume: number
  drumsMuted: boolean

  generatorSettings: {
    chordGenreKey: string
    drumGenreKey: string
    emptyGenre?: boolean
  }
  loops: number
  draft: boolean
}

export type ProgPart = {
  chordLayers: InstrumentLayer[]
  melodyLayers: InstrumentLayer[]
  drumLayers: InstrumentLayer[]
} & ProgPartCommon

export type ProgPartDb = {
  chordLayers: InstrumentLayerDb[]
  melodyLayers: InstrumentLayerDb[]
  drumLayers: InstrumentLayerDb[]
} & ProgPartCommon

export type ProgressionConfig = {
  view?: ViewConfig
  lyrics?: Lyrics
}

export type Project = {
  prog: Prog
  id: string
  name: string
  updatedAt: string
} & ProgressionConfig

export type Preview = {
  prog: Prog
  id: string
  name: string
  key: string
} & ProgressionConfig

export type Instrument = {
  _id: string
  name: string
  key: string
  category: string
  path: string
  premium: boolean
  genres: string[]
  hasPreview: boolean
}

export type Playstyle = {
  id: string
  name: string
  types: string[]
}

export type InstrumentLayerCommon = {
  octave: number
  volume: number
  muted: boolean
  bassEnabled: boolean
  bassStartMidi: number
  bassSpread: number
  bassDensity: boolean
  chordEnabled: boolean
  chordStartMidi: number
  chordSpread: number
  chordDensity: boolean
}

export type InstrumentLayerDb = {
  instrument: string
  playstyle: string
} & InstrumentLayerCommon

export type InstrumentLayer = {
  instrument: Instrument
  playstyle: Playstyle
} & InstrumentLayerCommon

export type Pattern = {
  name: string
  genre: string
  path: string
  premium: boolean

  draft?: boolean
}

export type SubscriptionPlan =
  | 'pro_subscription'
  | 'monthly_premium'
  | 'annual_premium'
  | 'christmas_premium'
  | 'platinum_monthly'
  | 'multi_platinum_monthly'
  | 'diamond_monthly'
  | 'platinum_yearly'
  | 'multi_platinum_yearly'
  | 'diamond_yearly'
  | 'platinum_monthly_direct'
  | 'platinum_yearly_direct'
  | 'platinum_yearly_canvas'
  | 'canvas_yearly'
  | 'canvas_monthly'
  | 'creator_monthly'
  | 'creator_yearly'
  | 'pro_monthly'
  | 'pro_yearly'

export type SubscriptionProductId = string | number

export type PaymentProvider = 'paddle' | 'stripe'

export type FrontendSubscriptionPlan =
  | 'free'
  | 'legacy'
  | 'platinum_monthly'
  | 'platinum_yearly'
  | 'multi_platinum_monthly'
  | 'multi_platinum_yearly'
  | 'diamond_monthly'
  | 'diamond_yearly'
  | 'premium_weekly'
  | 'premium_monthly'
  | 'premium_3-month'
  | 'premium_6-month'
  | 'premium_yearly'
  | 'pro_monthly'
  | 'pro_yearly'

export type FrontendUser = {
  id: string
  email: string
  name: string
  isAdmin: boolean
  subscriptionPlan: FrontendSubscriptionPlan
  isPaddleSubscription: boolean
  isStripeSubscriptionActive: boolean
  isStripeCustomer: boolean
  prefs: FrontendUserPrefs
}

export type FrontendUserPrefs = {
  undoRedoShown: boolean
  lyricsPreviewShown: boolean
  quizTaken: boolean
  quizOccupation?: string
  afterRegModalShown: boolean
  isTrialUsed: boolean
  lastBigFeatureShown: string
  delete_full_at: Date | null
  favouriteGenre: string
  musicLevel: string
  favouriteInstrument: string
  source: string
  source_detailed: string
  musicMakingLevel: string
}

export type FrontendUserDetailed = {
  limits?: {
    suggestions: { current: number; limit: number }
    generations: { current: number; limit: number }
    exports: { current: number; limit: number }
    voicings: { current: number; limit: number }
    lyrics_suggestions: { current: number; limit: number }
    projects: { current: number; limit: number }
    shares: { current: number; limit: number }
    rag: { current: number; limit: number }
    aiDemo: { current: number; limit: number }
  }
  nextResetDate: Date
  subscriptionActive: boolean
  subscriptionEffectiveDate: Date
  saleApplied: boolean
  cancelUrl: string
  updateUrl: string
  canvasSubscriptionActive: boolean
} & FrontendUser

export type GhostPost = {
  slug: string
  excerpt?: string
  og_title: string | undefined | null
  meta_title: string | undefined | null
  title: string | undefined | null
  og_description: string | undefined | null
  meta_description: string | undefined | null
  og_image: string | undefined | null
  feature_image: string | undefined | null
  html: string | undefined | null
  updated_at: string | undefined | null
  published_at: string | undefined | null
  canonical_url: string | undefined | null
  type: GhostPostType
  head_config?: GhostPostHeadConfig
  footer_config?: GhostPostFooterConfig
}

export type GhostPostType = 'v1' | 'guide-post' | 'seo-tool'

export type GhostPostHeadConfig = {
  chapterNum: number
  guideLink: string
  guideName: string
  description: string
}

export type GhostPostFooterConfig = {
  previous?: {
    title: string
    link: string
  }
  next?: {
    title: string
    link: string
  }
}

export type CompactGhostPost = {
  slug: string
  type: GhostPostType
  title: string | undefined | null
  feature_image: string | undefined | null
  meta_description: string | undefined | null
}

export type ApiRouteParams = {
  server: Express
  app: NextServer
}

export type AuthRoutesParams = {
  server: Express
  userPassport: any
}

export type LimitType =
  | 'generations'
  | 'suggestions'
  | 'exports'
  | 'voicings'
  | 'projects'
  | 'shares'
  | 'lyrics_suggestions'
  | 'rag'
  | 'aiDemo'

export const ADDING_TILE_ID = 100000222

export type TimelineState = {
  tiles: number[]
  widths: { [key: number]: number }
}

export type BlogPostConfig = {
  headings: { text: string; id: string }[]
  isSSG: boolean
  isSIG: boolean
}

export type PatternNote = {
  ticks: number
  duration?: number
}
export type PartPattern = { midi: MidiJSON | null; name?: string | undefined; id?: string; customId?: string }
export type ProgPatterns = { [key: number]: PartPattern }

export type GenerationsTrackConfig = {
  placement: 'presets' | 'track' | 'drummachine' | 'modal' | 'single-track'
  type: 'all' | 'drums' | 'chords'
  singleTrack?: string
}

export enum OnboardingStep {
  generate = 'generate',
  play = 'play',
  edit = 'edit',
  done = 'done',
}

export enum PreOnboardingTutorialStep {
  default = 'default',
  beginner = 'beginner',
  aspiring = 'aspiring',
  professional = 'professional',
  done = 'done',
}

export enum OneTimePurchases {
  canvas_subscription = 'canvas_subscription',
  framework_upsell = 'framework_upsell',
  canvas_bundle = 'canvas_bundle',
}

export type PaymentType = 'card' | 'paypal' | string

export type CanvasExtension = {
  _id: string
  title: string
  description: string
  instruments: string[]
  key: string
  status?: 'locked' | 'purchased'
  style?: string
  price?: number
  bpm: { from: number; to: number }
  works_best_keys?: string[]
}

export type CanvasBundle = {
  _id: string
  title: string
  price: number
  extensions: string[]
  key: string
  description: string
  status?: 'locked' | 'purchased'
}

export type TInfluencer = {
  _id: string
  name: string
  url: string
  avatar: Buffer
  project_ids: string[]
}

export type TGenre = {
  name: string
  key: string
  imageUrl: string
  type: string
  defaultTempo: number
  defaultInstrumentKey: string
  premium: boolean
  percGroups: {
    percTypes: { type: string; active: boolean }[]
    patterns: { key: string; buffer: string }[]
  }[]
}

export type TGenreShort = {
  name: string
  key: string
  imageUrl: string
  defaultInstrumentKey: string
  premium: boolean
}

export type TGenreGroup = {
  name: string
  key: string
  type: string
  genres: TGenreShort[]
}

export const getDefaultGenre = (): TGenre => ({
  name: '',
  key: '',
  imageUrl: '',
  type: '',
  defaultTempo: 0,
  defaultInstrumentKey: '',
  percGroups: [],
  premium: false,
})
