import * as Sentry from '@sentry/nextjs'
import React, { Component, ReactNode, ErrorInfo } from 'react'

import ErrorScreen from './ErrorScreen'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state to render fallback UI
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to an external service
    console.error('Error occurred:', error, errorInfo)
    Sentry.captureException(error, { extra: errorInfo as any })
  }

  handleRetry = () => {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen handleRetry={this.handleRetry} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
