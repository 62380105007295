import Script from 'next/script'
import React from 'react'

export const trackSnapPixelLead = (params: { user_email: string; firstname: string }) => {
  window.snaptr('track', 'ADD_CART', params)
}

const SnapchatPixel = () => {
  return (
    <>
      <Script defer>
        {`(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
            {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
            a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
            r.src=n;var u=t.getElementsByTagName(s)[0];
            u.parentNode.insertBefore(r,u);})(window,document,
            'https://sc-static.net/scevent.min.js');

            snaptr('init', 'de72b43f-5103-4c6a-9cee-2614f6e21068');

            snaptr('track', 'PAGE_VIEW');`}
      </Script>
    </>
  )
}

export default SnapchatPixel
