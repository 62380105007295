import { getRandomFromArr } from './array'
import { isNumber } from './stringUtils'
import { Instrument, InstrumentLayer, Playstyle } from './types'

export const SingleInstrumentKey = 'single-instrument-0-1'

export const guitarChordSamplerInstrument = {
  key: 'guitar-instrument',
  path: 'instruments/fe421b75-a3be-4707-bc72-11abcb568862/',
}
export const guitarInstrumentKeys = [guitarChordSamplerInstrument.key]

export const INSTRUMENT_CATEGORY_TO_PLASTYLE_TYPES: { [key: string]: string[] } = {
  keys: ['KEYS', 'BASS', 'PADS'],
  guitars: ['GUITARS'],
  synths: ['KEYS', 'BASS', 'PADS'],
  pads: ['PADS'],
  strings: ['PADS'],
  basses: ['BASS'],
  other: ['KEYS', 'BASS', 'PADS'],
}

export const findInstrumentByKey = (key: string, instruments: Instrument[]): Instrument => {
  return instruments.find((i) => i.key === key) || instruments[0]
}

export const findPlaystyleById = (id: string, playstyles: Playstyle[]): Playstyle => {
  return playstyles.find((i) => i.id === id) || defaultPlaystyle
}

export const defaultInstrument: Instrument = {
  _id: '',
  name: '',
  key: '',
  category: '',
  path: '',
  premium: false,
  genres: [],
  hasPreview: false,
}
export const defaultPlaystyle: Playstyle = {
  id: 'basic',
  name: 'Basic',
  types: [],
}
export const defaultLayer: InstrumentLayer = {
  volume: 100,
  muted: false,
  instrument: defaultInstrument,
  playstyle: defaultPlaystyle,
  bassEnabled: true,
  bassStartMidi: 24,
  bassSpread: 1,
  bassDensity: false,
  chordEnabled: true,
  chordStartMidi: 48,
  chordSpread: 1,
  chordDensity: false,
  octave: 0,
}

export const createLayer = (
  instrumentKey: string,
  instruments: Instrument[],
  playstyleId?: string,
  playstyles?: Playstyle[],
  prevLayer?: InstrumentLayer,
): InstrumentLayer => {
  const layer = {
    ...defaultLayer,
    ...prevLayer,
    instrument: findInstrumentByKey(instrumentKey, instruments),
  }

  if (playstyleId && playstyles) {
    layer.playstyle = findPlaystyleById(playstyleId, playstyles)
  }

  return layer
}

export const setupLayers = (
  layers: InstrumentLayer[],
  instrumentsData: Instrument[],
  playstylesData?: Playstyle[],
  instrumentKeyRaw?: string,
): InstrumentLayer[] => {
  const allowedInstruments = instrumentKeyRaw
    ? instrumentsData
    : instrumentsData.filter((instrument) => instrument.category !== 'basses')
  const instrumentKey = instrumentKeyRaw || getRandomFromArr(allowedInstruments).key

  const newLayers = layers.length
    ? layers.map((layer) => {
        if (layer.instrument._id) {
          return layer
        } else {
          const layerKey = typeof layer.instrument === 'string' ? layer.instrument : layer.instrument.key
          const playstyleId = typeof layer.playstyle === 'string' ? layer.playstyle : layer.playstyle.id
          return createLayer(layerKey, instrumentsData, playstyleId, playstylesData, layer)
        }
      })
    : [createLayer(instrumentKey, allowedInstruments, 'basic', playstylesData)]

  return newLayers
}

export const getInstrumentKey = (instrument: Instrument, layerIndex: number, partId: number) => {
  const instrumentKey = typeof instrument === 'string' ? instrument : instrument.key
  return `${instrumentKey}-${layerIndex}-${partId}`
}

export const getInstrumentKeyFromLayerKey = (layerKey: string): string => {
  const instrumentKey = layerKey.split('-').slice(0, -2).join('-')

  return instrumentKey
}

export const getLayerIndexFromInstrumentKey = (instrumentKey: string): number => {
  const partId = instrumentKey.split('-').slice(-2)[0]

  return isNumber(partId) ? +partId : 0
}

export const getPartIdFromInstrumentKey = (instrumentKey: string): number => {
  const partId = instrumentKey.split('-').slice(-1)[0]

  return isNumber(partId) ? +partId : 0
}

export const getPlaystyleIdByInstrumentKey = (
  instrumentKey: string,
  instruments: Instrument[],
  playstyleId: string,
  playstyles: Playstyle[],
) => {
  const currentPlaystyle = findPlaystyleById(playstyleId, playstyles)
  const newInstrument = findInstrumentByKey(instrumentKey, instruments)

  const allowedTypes = INSTRUMENT_CATEGORY_TO_PLASTYLE_TYPES[newInstrument.category]
  const allowedPlaystyles = playstyles.filter((ps) => allowedTypes && ps.types.some((t) => allowedTypes.includes(t)))

  const doesPlaystyleFitCategory = allowedPlaystyles.find((ps) => ps.id === currentPlaystyle.id)

  return doesPlaystyleFitCategory ? currentPlaystyle.id : getRandomFromArr(allowedPlaystyles).id
}
