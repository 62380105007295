import { GoogleTagManager } from '@next/third-parties/google'
import App, { AppContext, AppProps } from 'next/app'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { install } from 'resize-observer'

import ErrorBoundary from '../components/common/ErrorBoundary/ErrorBoundary'
import SnapchatPixel from '../components/scripts/SnapchatPixel/SnapchatPixel'
import { InternationalizationProvider } from '../context/InternationalizationContext'
import '../styles/globals.scss'
import { getPageText } from '../utils/nextUtils'

type Props = AppProps & {
  text?: { [key: string]: any }
  locale?: string
}

const { publicRuntimeConfig } = getConfig() || {}

const MyApp = ({ Component, pageProps, text, locale }: Props): JSX.Element => {
  const router = useRouter()
  useEffect(() => {
    setTimeout(() => {
      if (!window.ResizeObserver) install()
    }, 2000)
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(publicRuntimeConfig.FACEBOOK_PIXEL_ID)
        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })
  }, [])
  useEffect(() => {
    const loader = document.getElementById('pageLoader')
    loader?.remove()
  }, [])
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          if (registration.active && registration.active.scriptURL.includes('canvas-sw.js')) {
            registration.unregister()
          }
        })
      })
    }
  }, [])
  return (
    <ErrorBoundary>
      <InternationalizationProvider text={text || {}} locale={locale || 'en'}>
        <GoogleTagManager gtmId='GTM-TJS5HSC' />
        <SnapchatPixel />
        <Component {...pageProps} />
      </InternationalizationProvider>
    </ErrorBoundary>
  )
}

MyApp.getInitialProps = async (context: AppContext) => {
  const { locale, pathname } = context.ctx

  const ctx = await App.getInitialProps(context)
  const text = await getPageText(locale, pathname)

  return { ...ctx, text, locale }
}

export default MyApp
