import Image from 'next/image'
import React, { FC } from 'react'

import { COPYRIGHT } from '../../../utils/constants'
import styles from './ErrorScreen.module.scss'

const STRINGS = {
  footer: COPYRIGHT,
}

type Props = {
  handleRetry: any
}

const ErrorScreen: FC<Props> = ({ handleRetry = () => {} }) => {
  return (
    <div className={styles.container}>
      <Image src='/static/images/logo.svg' height={24} width={182} priority />

      <div className={styles.content}>
        <div className={styles.title}>Oops, something went wrong.</div>
        <div className={styles.subtitle}>Please try refreshing the page or contact support.</div>
        <div className={styles.btn} onClick={handleRetry}>
          Retry
        </div>
      </div>

      <div className={styles.footer}>{STRINGS.footer}</div>
    </div>
  )
}

export default ErrorScreen
