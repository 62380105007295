export const formatNumber = (x: string | number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const secondsToString = (seconds: number) => {
  const minutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const sec = Math.round((((seconds % 31536000) % 86400) % 3600) % 60)
  return `${minutes} m ${sec} sec`
}

export const secondsToTime = (seconds: number | null, addSpaces = false): string => {
  if (seconds === null) return '00:00'
  const m = Math.floor(seconds / 60)
  const s = seconds % 60

  const additionalSpace = addSpaces ? ' ' : ''

  return `${m < 10 ? `0${m}${additionalSpace}` : `${m}${additionalSpace}`}:${
    s < 10 ? `${additionalSpace}0${s}` : `${additionalSpace}${s}`
  }`
}

export function capitalize(string: string) {
  return (string || '').charAt(0).toUpperCase() + (string || '').slice(1).toLowerCase()
}

export function naturalSort(a: string, b: string) {
  const aParts = a.split(/(\d+)/)
  const bParts = b.split(/(\d+)/)

  for (let i = 0; i < aParts.length; i++) {
    if (bParts.length < i + 1) {
      return 1
    }

    const aPart = aParts[i]
    const bPart = bParts[i]

    if (aPart !== bPart) {
      // @ts-ignore
      if (!isNaN(aPart) && !isNaN(bPart)) {
        return parseInt(aPart) - parseInt(bPart)
      } else {
        return aPart.localeCompare(bPart)
      }
    }
  }

  if (aParts.length < bParts.length) {
    return -1
  } else {
    return 0
  }
}

export const promisify = (func: (cb: () => void) => void) => {
  return new Promise<void>((resolve) => {
    func(() => resolve())
  })
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}
export const validateLoginCode = (code: string) => {
  return code.length === 7 && code !== '_______'
}

export function generateStr(length: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export function getReadingTime(text: string) {
  const wpm = 225
  const words = text.trim().split(/\s+/).length
  return Math.ceil(words / wpm)
}

export const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

export const getCookie = (name: string) => {
  return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop()
}
export const getUrlLocale = (route: string) => {
  const locales = ['/en', '/es', '/jp']

  return locales.find((locale) => route.endsWith(locale) || route.includes(locale + '/')) || ''
}
export const windowOpen = (url: string, target?: string, shouldFocus = false) => {
  const locale = url.startsWith('/') ? getUrlLocale(window.location.href.split('?')[0]) : ''

  setTimeout(() => {
    const instance = window.open(locale + url, target)
    if (shouldFocus) {
      instance?.focus()
    }
  }, 0)
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })
}

export function base64ToChunks(base64: string, chunkSize = 10000) {
  const chunks = []
  let index = 0
  while (index < base64.length) {
    chunks.push(base64.slice(index, (index += chunkSize)))
  }
  return chunks
}

export const randomFixedInteger = function (length: number) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1))
}

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isNumber = (str: string) => /^\d+$/.test(str)

export const copyObj = (obj: { [key: string | number]: any }) => JSON.parse(JSON.stringify(obj))

export function mergeObjects(...s: any[]): any {
  if (!s.length) return {}
  const target = {}

  const seenObjects = new WeakMap()

  function merge(target: any, source: any): any {
    for (const key in source) {
      if (source[key] && typeof source[key] === 'object') {
        if (!target[key]) {
          target[key] = Array.isArray(source[key]) ? [] : {}
        }
        if (!seenObjects.has(source[key])) {
          seenObjects.set(source[key], true)
          merge(target[key], source[key])
        }
      } else {
        target[key] = source[key]
      }
    }
    return target
  }

  for (const source of s) merge(target, source)

  return target
}

export const country2flag = (countryCode: string): string => {
  return countryCode
    .toUpperCase()
    .split('')
    .map((char) => String.fromCodePoint(char.charCodeAt(0) + 0x1f1a5))
    .join('')
}
