import { ParsedUrlQuery } from 'querystring'

import {
  CompactGhostPost,
  GhostPost,
  FrontendUser,
  FrontendUserDetailed,
  Preview,
  BlogPostConfig,
  TInfluencer,
  PreOnboardingTutorialStep,
} from './types'

export const getDistinctIdFromQuery = (query: ParsedUrlQuery) => {
  return query.distinctId as string
}

export const getExperimentsFromQuery = (query: ParsedUrlQuery) => {
  return JSON.parse((query.experiments || 'null') as string) as number[]
}

export const getUserFromQuery = (query: ParsedUrlQuery) => {
  return JSON.parse((query.user || 'null') as string) as FrontendUser
}

export const getUserDetailedFromQuery = (query: ParsedUrlQuery) => {
  return JSON.parse((query.user || 'null') as string) as FrontendUserDetailed
}

export const getPostsFromQuery = (query: ParsedUrlQuery) => {
  return JSON.parse((query.posts || 'null') as string) as CompactGhostPost[]
}

export const getPostFromQuery = (query: ParsedUrlQuery) => {
  return JSON.parse((query.post || 'null') as string) as GhostPost
}

export const getPostConfigFromQuery = (query: ParsedUrlQuery) => {
  return JSON.parse((query.postConfig || 'null') as string) as BlogPostConfig
}

export const getPreviewFromQuery = (query: ParsedUrlQuery) => {
  return JSON.parse((query.preview || 'null') as string) as Preview
}

export const getInfluencerFromQuery = (query: ParsedUrlQuery) => {
  return JSON.parse((query.influencer || 'null') as string) as TInfluencer
}

export const getPreOnboardingTutorialStep = (query: ParsedUrlQuery) => {
  const user = getUserDetailedFromQuery(query)

  const isPreOnboardingTutorialDone =
    query.preOnboardingTutorialDone ||
    [
      PreOnboardingTutorialStep.beginner,
      PreOnboardingTutorialStep.aspiring,
      PreOnboardingTutorialStep.professional,
    ].includes(user?.prefs?.musicMakingLevel as any)

  return isPreOnboardingTutorialDone ? PreOnboardingTutorialStep.done : (query.preOnboardingTutorialStep as string)
}

export const isInStandaloneMode = () =>
  (typeof window !== undefined && window.matchMedia('(display-mode: standalone)').matches) ||
  // @ts-ignore
  window.navigator.standalone ||
  document.referrer.includes('android-app://')

export const getTranslateFromLocale = async (locale: string | undefined, pathname: string) => {
  const language = locale || 'en'
  const fileName = pathname === '/pages/' ? '/pages/index' : pathname

  try {
    return (await import(`../translations/${language}${fileName}.json`)).default
  } catch (e) {
    return {}
  }
}

export const getPageText = async (locale: string | undefined, pathname: string) => {
  return await getTranslateFromLocale(locale, `/pages${pathname}`)
}

export const getComponentText = async (locale: string | undefined, componentName: string) => {
  return await getTranslateFromLocale(locale, `/components/${componentName}`)
}
