import React, { ReactNode, useState } from 'react'

import { getComponentText } from '../utils/nextUtils'

type FetchType = {
  text: { [key: string]: any }
  locale: string
  loaded: boolean
  addComponentText: (componentName: string) => void
  getText: (key: string, obj?: { [key: string]: any }) => { [key: string]: any }
  isTextReady: (v: any) => boolean
}

export const InternationalizationContext = React.createContext<FetchType>({
  text: {},
  locale: '',
  loaded: false,
  addComponentText: () => {},
  getText: () => ({}),
  isTextReady: () => false,
})

export const InternationalizationProvider = ({
  children,
  locale,
  text: initialText,
}: {
  children: ReactNode
  locale: string
  text: { [key: string]: any }
}) => {
  const [text, setText] = useState(initialText)
  const [numOfLoadings, setNumOfLoadings] = useState(0)

  const addComponentText = async (componentName: string) => {
    setNumOfLoadings((v) => ++v)

    const newText = await getComponentText(locale, componentName)

    setText((v) => ({ ...v, ...newText }))
    setNumOfLoadings((v) => --v)
  }

  const getText = (key: string, obj = text): { [key: string]: any } => {
    const isValid = (v: any) => v && v !== null && typeof v === 'object' && !Array.isArray(v) && Object.keys(v).length

    for (const k in obj) {
      if (!isValid(obj[k])) continue

      if (k === key) return obj[k]

      const value = getText(key, obj[k])
      if (isValid(value)) return value
    }

    return {}
  }
  const isTextReady = (v: any) => !!Object.keys(v || {}).length

  return (
    <InternationalizationContext.Provider
      value={{
        text,
        locale,
        loaded: isTextReady(text) && !numOfLoadings,
        addComponentText,
        getText,
        isTextReady,
      }}
    >
      {children}
    </InternationalizationContext.Provider>
  )
}

export const useInternationalization = () => React.useContext<FetchType>(InternationalizationContext)
